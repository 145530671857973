import React, { useEffect, useState } from 'react';
import { SubmitToCSVtest } from '../js/functions';
import SelectionDPtest from './selectiontest'
import { Link } from 'react-router-dom';

import Navbar from './navbar';

function SelectedDP() {

    // ETATS
    const [erreur, setErreur] = useState(null);
    const [inputSets, setInputSets] = useState([{ selectedOptionPRDE: 'PR', typeProductFiltered: [], designation: '', maskedDesignation: '', ecategoryFiltered: [], ecategory2Filtered: [], ecategory3Filtered: [], ecategory4Filtered: [], ecategory5Filtered: []}]);
    const [SelectorArticle, setSelectorArticle] = useState([]);
    const [Family, setFamily] = useState([]);
    const [TypeProduct, setTypeProduct] = useState([]);
    const [Fournisseur, setFournisseur] = useState([]);
    const [Annee, setAnnee] = useState([])
    const [Sexe, setSexe] = useState([]);
    const [Category, setCategory] = useState([]);
    const [commercialise, setCommercialise] = useState([]);
    const [stocke, setStocke] = useState([]);
    const [divers, setDivers] = useState([]);
    const [tarifaumodele, setTarifaumodele] = useState([]);
    const [typeProductFiltered, setTypeProductFiltered] = useState([]);
    const [Ecategory1, setEcategory1] = useState([]);
    const [Ecategory2, setEcategory2] = useState([]);
    const [Ecategory3, setEcategory3] = useState([]);
    const [Ecategory4, setEcategory4] = useState([]);
    const [Ecategory5, setEcategory5] = useState([]);






    // Demande vers l'API
    useEffect(() => {
        console.log("API URL:", process.env.VOOSE_API_URL); // Vérifiez si l'URL est affichée correctement
        fetch(`https://api.voose.fr/create`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Erreur réseau');
                }
                return response.json();
            })
            .then(data => {
                setSelectorArticle(data.SelectorArticle);
                setFamily(data.Family);
                setTypeProduct(data.TypeProduct);
                setFournisseur(data.Fournisseur);
                setAnnee(data.Annee);
                setSexe(data.Sexe);
                setCategory(data.Category);
                setEcategory1(data.Ecategory);
                setEcategory2(data.Ecategory);
                setEcategory3(data.Ecategory);
                setEcategory4(data.Ecategory);
                setEcategory5(data.Ecategory);
                // Et ainsi de suite pour les autres ensembles de données
            })
            .catch(err => {
                console.error('Erreur lors de la récupération des données:', err);
                setErreur(err.message);
            });
    }, []);



    /* ----------------------------------------------------------------------------------------- */
    /*                                        FUNCTIONS                                          */
    /* ----------------------------------------------------------------------------------------- */


    // FONCTION - Product Or Declinaison ?
    const handleSelectChangePRDE = (event, index) => {
        const newInputSets = [...inputSets];
        newInputSets[index].selectedOptionPRDE = event.target.value;
        setInputSets(newInputSets);
        console.log(index + "Colonne A" + event.target.value)
    };

    // FONCTION - Code Article
    const handleCodeArticleChange = (event, index) => {
        const newInputSets = [...inputSets];
        newInputSets[index].codeArticle = event.target.value;
        setInputSets(newInputSets);
        console.log(index + "Colonne B" + event.target.value)
    };

    // FONCTION - Désignation
    const handleDesignationChange = (event, index) => {
        const newInputSets = [...inputSets];
        newInputSets[index].designation = event.target.value;
        setInputSets(newInputSets);
        console.log(index + "Colonne C" + event.target.value)
    };

    // FONCTION - Family
    const handleSelectChangeFamily = (event, index) => {
        const newInputSets = [...inputSets];
        const valeurSelectionnee = event.target.value;

        const selectedFamily = Family.find(family => family.value === valeurSelectionnee);
        const hashtag = selectedFamily ? selectedFamily.hashtag : '';

        newInputSets[index].selectedOptionFamily = valeurSelectionnee;
        newInputSets[index].hashtagFamily = hashtag;
        newInputSets[index].ecategory = selectedFamily.ecategory || '';

        const filteredTypeProducts = TypeProduct.filter(product => product.hashtag === hashtag);
        const filteredEcategories = Ecategory1.filter(category => category.hashtag === hashtag);
        const filteredEcategories2 = Ecategory2.filter(category => category.hashtag === hashtag);
        const filteredEcategories3 = Ecategory3.filter(category => category.hashtag === hashtag);
        const filteredEcategories4 = Ecategory4.filter(category => category.hashtag === hashtag);
        const filteredEcategories5 = Ecategory5.filter(category => category.hashtag === hashtag);

        // Mise à jour des types de produits filtrés pour cette ligne spécifique
        newInputSets[index].typeProductFiltered = filteredTypeProducts;
        newInputSets[index].ecategoryFiltered = filteredEcategories;
        newInputSets[index].ecategory2Filtered = filteredEcategories2;
        newInputSets[index].ecategory3Filtered = filteredEcategories3;
        newInputSets[index].ecategory4Filtered = filteredEcategories4;
        newInputSets[index].ecategory5Filtered = filteredEcategories5;

        setInputSets(newInputSets);
    };


    // FONCTION - Type Product
    const handleSelectChangeTypeProduct = (event, index) => {
        const valeurSelectionneeTypeProduct = event.target.value;
        const newInputSets = [...inputSets];

        const selectedTypeProduct = newInputSets[index].typeProductFiltered.find(option => option.value === valeurSelectionneeTypeProduct);

        if (selectedTypeProduct) {
            // Assurez-vous que ces champs existent dans vos données et les mettre à jour en conséquence
            newInputSets[index].codeArticle = selectedTypeProduct.code;
            newInputSets[index].designation = selectedTypeProduct.name + ' - ' + (newInputSets[index].designation || '');
            newInputSets[index].tDecli = selectedTypeProduct.t_decli || '';
            newInputSets[index].cDecli = selectedTypeProduct.c_decli || '';
            newInputSets[index].mDecli = selectedTypeProduct.m_decli || '';
        }

        newInputSets[index].selectedOptionTypeProduct = valeurSelectionneeTypeProduct;

        setInputSets(newInputSets);
    };



    // FONCTION - Sexe
    const handleSelectChangeSexe = (event, index) => {
        const newInputSets = [...inputSets];
        newInputSets[index].selectedOptionSexe = event.target.value;

        setInputSets(newInputSets);
        console.log(index + "Colonne H" + event.target.value)
    };

    // FONCTION - Annee
    const handleSelectChangeAnnee = (event, index) => {
        const selectedAnneeValue = event.target.value;
        const newInputSets = [...inputSets];
        const inputSet = newInputSets[index];

        // Utiliser l'état local typeProductFiltered de cette ligne spécifique pour trouver le produit sélectionné
        const selectedTypeProduct = inputSet.typeProductFiltered.find(product => product.value === inputSet.selectedOptionTypeProduct);

        // Si un type de produit a été sélectionné précédemment, utiliser son code
        const productCode = selectedTypeProduct ? selectedTypeProduct.code : '';

        // Vérifiez si une marque a été sélectionnée et utilisez son code, si applicable
        const selectedMarqueObj = Fournisseur.find(marque => marque.value === inputSet.selectedOptionMarque);
        const marqueCode = selectedMarqueObj ? selectedMarqueObj.code_article : '';

        // Construisez le codeArticle avec le code du produit, le code de la marque, et les deux derniers chiffres de l'année
        const lastTwoDigits = selectedAnneeValue.substring(selectedAnneeValue.length - 2);
        inputSet.codeArticle = `${productCode}${marqueCode}${lastTwoDigits}`;
        inputSet.selectedOptionAnnee = selectedAnneeValue;

        setInputSets(newInputSets);
    };


    // FONCTION - Marque
    const handleSelectChangeMarque = (event, index) => {
        const selectedMarqueValue = event.target.value;
        const newInputSets = [...inputSets];
        const inputSet = newInputSets[index];

        // Trouver l'objet Marque sélectionné pour obtenir son code
        const selectedMarqueObj = Fournisseur.find(marque => marque.value === selectedMarqueValue);
        const marqueCode = selectedMarqueObj ? selectedMarqueObj.code_article : '';

        // Utiliser l'état local typeProductFiltered de cette ligne spécifique pour trouver le produit sélectionné
        const selectedTypeProduct = inputSet.typeProductFiltered.find(product => product.value === inputSet.selectedOptionTypeProduct);

        // Si un type de produit a été sélectionné précédemment, utiliser son code
        const productCode = selectedTypeProduct ? selectedTypeProduct.code : '';
        const marqueName = selectedMarqueObj ? selectedMarqueObj.name : ''; // Ajout pour récupérer le nom de la marque
        const achatFournisseur = selectedMarqueObj ? selectedMarqueObj.achat_fournisseur : '';

        // Construisez le codeArticle avec le code du produit, le code de la marque, et les deux derniers chiffres de l'année si elle a été sélectionnée
        const annee = inputSet.selectedOptionAnnee || '';
        const lastTwoDigits = annee.substring(annee.length - 2);

        inputSet.codeArticle = `${productCode}${marqueCode}${lastTwoDigits}`;
        inputSet.selectedOptionMarque = selectedMarqueValue;

        // Ajouter le nom de la marque à la désignation si elle n'est pas déjà incluse
        if (!inputSet.designation.includes(marqueName)) {
            inputSet.designation = `${marqueName} - ${inputSet.designation || ''}`;
        }

        inputSet.maskedDesignation = inputSet.designation.replace(`${marqueName} - `, '');

        inputSet.selectedOptionAchatFournisseur = achatFournisseur;
        setInputSets(newInputSets);
    };


    const handleSelectChangeAchatFournisseur = (event, index) => {
        console.log(index + " Colonne P " + event.target.value);
    };

    // FONCTION - Catégorie
    const handleSelectChangeCategorie = (event, index) => {
        const newInputSets = [...inputSets];
        newInputSets[index].selectedOptionCategorie = event.target.value;

        setInputSets(newInputSets);
        console.log(index + "Colonne K" + event.target.value)
    };

    // FONCTION - Commercialisé
    const handleCheckboxCommercialiseChange = (e) => {
        setCommercialise(e.target.checked ? "VRAI" : "FAUX");
    };

    // FONCTION - Stocké
    const handleCheckboxStockChange = (e) => {
        setStocke(e.target.checked ? "VRAI" : "FAUX");
    };

    // FONCTION - Divers
    const handleCheckboxDiversChange = (e) => {
        setDivers(e.target.checked ? "VRAI" : "FAUX");
    };

    // FONCTION - Tarif au modèle
    const handleCheckboxTarifAuModeleChange = (e) => {
        setTarifaumodele(e.target.checked ? "VRAI" : "FAUX");
    };

    // FONCTION - Prix de Vente
    const handlePDVChange = (event, index) => {
        const newInputSets = [...inputSets];
        newInputSets[index].pdv = event.target.value;
        setInputSets(newInputSets);
        console.log(index + "Colonne C" + event.target.value)
    };

    const handleTDecliChange = (event, index, type) => {
        console.log(`${index} Colonne ${type.toUpperCase()} ` + event.target.value);
    };


    const handleSelectChangeEcategory = (event, index) => {
        const newInputSets = [...inputSets];
        const selectedValue = event.target.value;
        newInputSets[index].selectedOptionEcategory = selectedValue;
        setInputSets(newInputSets);
    };

    const handleSelectChangeEcategory2 = (event, index) => {
        const newInputSets = [...inputSets];
        const selectedValue = event.target.value;
        newInputSets[index].selectedOptionEcategory2 = selectedValue;
        setInputSets(newInputSets);
    };

    const handleSelectChangeEcategory3 = (event, index) => {
        const newInputSets = [...inputSets];
        const selectedValue = event.target.value;
        newInputSets[index].selectedOptionEcategory3 = selectedValue;
        setInputSets(newInputSets);
    };

    const handleSelectChangeEcategory4 = (event, index) => {
        const newInputSets = [...inputSets];
        const selectedValue = event.target.value;
        newInputSets[index].selectedOptionEcategory4 = selectedValue;
        setInputSets(newInputSets);
    };

    const handleSelectChangeEcategory5 = (event, index) => {
        const newInputSets = [...inputSets];
        const selectedValue = event.target.value;
        newInputSets[index].selectedOptionEcategory5 = selectedValue;
        setInputSets(newInputSets);
    };




    /* ----------------------------------------------------------------------------------------- */
    /*                                       OTHER FUNCTIONS                                     */
    /* ----------------------------------------------------------------------------------------- */

    // FONCTION - Ajouter une ligne
    const addInputSet = () => {
        setInputSets([...inputSets, { selectedOptionPRDE: 'PR', typeProductFiltered: [], ecategoryFiltered: [], ecategory2Filtered: [], ecategory3Filtered: [], ecategory4Filtered: [], ecategory5Filtered: []}]);
    };

    // FONCTION - Supprimer une ligne
    const removeInputSet = (index) => {
        const newInputSets = inputSets.filter((_, i) => i !== index);
        setInputSets(newInputSets);
    };

    // FONCTION - Duppliquer une ligne
    const duplicateInputSet = (index) => {
        const inputSetToDuplicate = inputSets[index];

        setInputSets([...inputSets, { ...inputSetToDuplicate }]);
    };

    // FONCTION - Déplacer un inputSet vers le haut
    const moveInputSetUp = (index) => {
        if (index > 0) {
            const newInputSets = [...inputSets];
            const [movedInputSet] = newInputSets.splice(index, 1); // Retirer l'élément
            newInputSets.splice(index - 1, 0, movedInputSet); // Insérer à la nouvelle position
            setInputSets(newInputSets); // Mettre à jour l'état
        }
    };

    // FONCTION - Déplacer un inputSet vers le bas
    const moveInputSetDown = (index) => {
        if (index < inputSets.length - 1) {
            const newInputSets = [...inputSets];
            const [movedInputSet] = newInputSets.splice(index, 1); // Retirer l'élément
            newInputSets.splice(index + 1, 0, movedInputSet); // Insérer à la nouvelle position
            setInputSets(newInputSets); // Mettre à jour l'état
        }
    };

    // FONCTION - Reset les champs à 0
    const resetForm = () => {
        window.location.reload();
    }



    if (erreur) {
        return <div>Erreur: {erreur}</div>;
    }

    return (
        <div className='dashboard-container'>
            <Navbar />

            <div className='container-create'>
                <div className='flexing2'>
                    <div className='container-text'>

                        <div>
                            <h1>Créer</h1>
                            <p className='desc'>Pour connaître plus d'informations sur le fonctionement de la création. <Link to="/docs">Cliquez ici</Link>.</p>
                        </div>

                        {/* <Success /> */}

                    </div>


                    <div>
                        <form>
                            <div className="flexing2">
                                <input type="text" id="name_file" placeholder='Nom de votre fichier'></input>
                                <button type='button' className='box-export' onClick={(event) => SubmitToCSVtest(event)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-file-export" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#3C3C53" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                                        <path d="M11.5 21h-4.5a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v5m-5 6h7m-3 -3l3 3l-3 3" />
                                    </svg>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>

                <form>
                    {inputSets.map((inputSet, index) => (
                        <div key={index} className='block-inputs2'>
                            <div className="box">
                                <div className='vertical-btn'>
                                    <button type="button" onClick={() => moveInputSetUp(index)}>↑</button>
                                    <button type="button" onClick={() => moveInputSetDown(index)}>↓</button>
                                    {inputSets.length > 1 && (
                                        <button type="button" onClick={() => removeInputSet(index)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-trash" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#3C3C53" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path d="M4 7l16 0" />
                                                <path d="M10 11l0 6" />
                                                <path d="M14 11l0 6" />
                                                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                            </svg>
                                        </button>

                                    )}

                                    <button type="button" onClick={() => duplicateInputSet(index)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-files" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#3C3C53" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M15 3v4a1 1 0 0 0 1 1h4" />
                                            <path d="M18 17h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h4l5 5v7a2 2 0 0 1 -2 2z" />
                                            <path d="M16 17v2a2 2 0 0 1 -2 2h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h2" />
                                        </svg>
                                    </button>
                                </div>
                            </div>


                            {/* Si DE est choisie */}
                            {inputSet.selectedOptionPRDE === 'DE' && (
                                <>
                                    <SelectionDPtest 
                                        inputSet={inputSet} // Passer l'inputSet à SelectionDPtest
                                        onChange={(updatedInputSet) => {
                                            const newInputSets = [...inputSets];
                                            newInputSets[index] = updatedInputSet; // Mettre à jour l'inputSet avec les nouvelles valeurs
                                            setInputSets(newInputSets);
                                        }}
                                    />
                                </>
                            )}

                            {/* Si PR est choisie */}
                            {inputSet.selectedOptionPRDE === 'PR' && (
                                <>
                                    <div className="box">
                                        <div className="container-input">
                                            <select name={`Productordecli_${index}`} className="input-select" id={`a_colonne_${index}`} onChange={(e) => handleSelectChangePRDE(e, index)} value={inputSet.selectedOptionPRDE || ''}>
                                                <option value="">PR / DE</option>
                                                {SelectorArticle.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.name}
                                                    </option>
                                                ))}
                                            </select>
                                            <div className='texter-btn'>A</div>
                                        </div>

                                        {/*
                                        <div className="container-input">
                                            <select name={`Productordecli_${index}`} className="input-select" id={`a_colonne_${index}`} onChange={(e) => handleSelectChangePRDE(e, index)} value={inputSet.selectedOptionPRDE || ''}>
                                                <option value="">PR / DE</option>
                                                {SelectorArticle.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.name}
                                                    </option>
                                                ))}
                                            </select>
                                            <div className='texter-btn'>A</div>
                                        </div>
                                        */}

                                        <div className='container-input'>
                                            {/* <label htmlFor={`b_${index}`}>Code</label> */}
                                            <input type="text" id={`b_colonne_${index}`} value={inputSet.codeArticle || ''} onChange={(e) => handleCodeArticleChange(e, index)} placeholder='Code Article' />
                                            <div className='texter-btn'>B</div>
                                        </div>

                                        <div className='container-input'>
                                            {/* <label htmlFor={`c_${index}`}>Code Barre</label> */}
                                            <input type="text" id={`c_colonne_${index}`} value="<A générer>" />
                                            <div className='texter-btn'>C</div>
                                        </div>

                                        <div className='container-input'>
                                            {/* <label htmlFor={`achatreference_${index}`}>Achat Réference</label> */}
                                            <input type="text" id={`q_colonne_${index}`} placeholder='Achat Réference' />
                                            <div className='texter-btn'>Q</div>
                                        </div>
                                    </div>

                                    <div className="box">
                                        <div className='container-input blocked'>
                                            {/* <label htmlFor={`d_${index}`}></label> */}
                                            <input type="text" id={`d_colonne_${index}`} readOnly="readonly" />
                                            <div className='texter-btn'>D</div>
                                        </div>

                                        <div className='container-input'>
                                            {/* <label htmlFor={`e_${index}`}>Désignation</label> */}
                                            <input type="text" id={`e_colonne_${index}`} value={inputSet.designation || ''} onChange={(e) => handleDesignationChange(e, index)} placeholder='Désignation' />
                                            <div className='texter-btn'>E</div>
                                        </div>

                                        <div className='container-input'>
                                            {/* <label htmlFor={`edesignation_${index}`}>e-designation</label> */}
                                            <input type="text" id={`af_colonne_${index}`} value={inputSet.maskedDesignation || ''} onChange={(e) => handleDesignationChange(e, index)} placeholder='E-Désignation' />
                                            <div className='texter-btn'>AF</div>
                                        </div>
                                    </div>

                                    <div className="box">
                                        <div className="flexing">
                                            <div className='container-input'>
                                                <select name={`Family_${index}`} id={`f_colonne_${index}`} onChange={(e) => handleSelectChangeFamily(e, index)} value={inputSet.selectedOptionFamily || ''}>
                                                    <option value="">Famille</option>
                                                    {Family.map(option => (
                                                        <option key={option.id} value={option.value}>
                                                            {option.name}
                                                        </option>
                                                    ))}
                                                </select>
                                                <div className='texter-btn'>F</div>
                                            </div>

                                            <div className='container-input'>
                                                <select name={`TypeProduct_${index}`} id={`g_colonne_${index}`} onChange={(e) => handleSelectChangeTypeProduct(e, index)} value={inputSet.selectedOptionTypeProduct || ''}>
                                                    <option value="">Type de produit</option>
                                                    {inputSet.typeProductFiltered.map((option) => (
                                                        <option key={option.id} value={option.value}>
                                                            {option.name}
                                                        </option>
                                                    ))}
                                                </select>
                                                <div className='texter-btn'>G</div>
                                            </div>
                                        </div>

                                        <div className="flexing">
                                            <div className="container-input">
                                                <select name={`Sexe_${index}`} id={`h_colonne_${index}`} onChange={(e) => handleSelectChangeSexe(e, index)} value={inputSet.selectedOptionSexe || ''}>
                                                    <option value="">Sexe</option>
                                                    {Sexe.map(option => (
                                                        <option key={option.id} value={option.value}>
                                                            {option.name}
                                                        </option>
                                                    ))}
                                                </select>
                                                <div className='texter-btn'>H</div>
                                            </div>

                                            <div className="container-input">
                                                <select name={`Annee_${index}`} id={`i_colonne_${index}`} onChange={(e) => handleSelectChangeAnnee(e, index)} value={inputSet.selectedOptionAnnee || ''}>
                                                    <option value="">Année</option>
                                                    {Annee.map(option => (
                                                        <option key={option.id} value={option.value}>
                                                            {option.name}
                                                        </option>
                                                    ))}
                                                </select>
                                                <div className='texter-btn'>I</div>
                                            </div>
                                        </div>


                                        <div className="flexing">
                                            <div className="container-input">
                                                <select name={`Marque_${index}`} id={`j_colonne_${index}`} onChange={(e) => handleSelectChangeMarque(e, index)} value={inputSet.selectedOptionMarque || ''} placeholder="Marque">
                                                    <option value="">Marque</option>
                                                    {Fournisseur.map(option => (
                                                        <option key={option.id} value={option.value}>
                                                            {option.name}
                                                        </option>
                                                    ))}
                                                </select>
                                                <div className='texter-btn'>J</div>
                                            </div>

                                            <div className="container-input blocked">
                                                <select name={`achatfournisseur_${index}`} id={`p_colonne_${index}`} onChange={(e) => handleSelectChangeAchatFournisseur(e, index)} value={inputSet.selectedOptionAchatFournisseur || ''}>
                                                    <option value="">Marque</option>
                                                    {Fournisseur.map(option => (
                                                        <option key={option.id} value={option.achat_fournisseur}>
                                                            {option.achat_fournisseur}
                                                        </option>
                                                    ))}
                                                </select>
                                                <div className='texter-btn'>P</div>
                                            </div>

                                            <div className="container-input">
                                                <select name={`Categorie_${index}`} id={`k_colonne_${index}`} onChange={(e) => handleSelectChangeCategorie(e, index)} value={inputSet.selectedOptionCategorie || ''}>
                                                    <option value="">Catégorie</option>
                                                    {Category.map(option => (
                                                        <option key={option.id} value={option.value}>
                                                            {option.name}
                                                        </option>
                                                    ))}
                                                </select>
                                                <div className='texter-btn'>K</div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className='box blocked'>
                                        <div>
                                            <h3>Stocks</h3>
                                            <p>Cochez <span>L</span> & <span>M</span> par défaut</p>
                                        </div>
                                        <div className='container-input2'>
                                            {/* <label htmlFor={`commercialise_${index}`}>Commercialisé</label> */}
                                            <label class="toggler-wrapper style-1">
                                                <input type="checkbox"
                                                    id={`l_colonne_${index}`}
                                                    onChange={handleCheckboxCommercialiseChange}
                                                    checked={commercialise === "VRAI"}
                                                />
                                                <div class="toggler-slider">
                                                    <div class="toggler-knob"></div>
                                                </div>
                                            </label>
                                            <div className='texter-btn'>L</div>
                                        </div>

                                        <div className='container-input2'>
                                            {/* <label htmlFor={`stocke_${index}`}>Stocké</label> */}
                                            <label class="toggler-wrapper style-1">
                                                <input type="checkbox"
                                                    id={`m_colonne_${index}`}
                                                    onChange={handleCheckboxStockChange}
                                                    checked={stocke === "VRAI"} />
                                                <div class="toggler-slider">
                                                    <div class="toggler-knob"></div>
                                                </div>
                                            </label>
                                            <div className='texter-btn'>M</div>

                                        </div>

                                        <div className='container-input blocked'>
                                            {/* <label htmlFor={`divers_${index}`}>Divers</label> */}
                                            <input
                                                className='checkbox-input'
                                                type="checkbox"
                                                id={`n_colonne_${index}`}
                                                onChange={handleCheckboxDiversChange}
                                                checked={divers === "VRAI"}
                                            />
                                            <div className='texter-btn'>N</div>
                                        </div>

                                        <div className='container-input blocked'>
                                            {/* <label htmlFor={`tarifaumodele_${index}`}>Tarif au Modèle</label> */}
                                            <input
                                                className='checkbox-input'
                                                type="checkbox"
                                                id={`o_colonne_${index}`}
                                                onChange={handleCheckboxTarifAuModeleChange}
                                                checked={tarifaumodele === "VRAI"}
                                            />
                                            <div className='texter-btn'>O</div>
                                        </div>
                                    </div>

                                    <div className='box'>
                                        <div className='container-input'>
                                            {/* <label htmlFor={`prixachatht_${index}`}>Prix Achat HT</label> */}
                                            <input type="text" id={`r_colonne_${index}`} placeholder='Prix Achat HT' />
                                            <div className='texter-btn'>R</div>
                                        </div>

                                        <div className='container-input'>
                                            {/* <label htmlFor={`remise_${index}`}>Remise</label> */}
                                            <input type="text" id={`s_colonne_${index}`} placeholder='Remise' />
                                            <div className='texter-btn'>S</div>
                                        </div>

                                        <div className='container-input'>
                                            {/* <label htmlFor={`prixventettc_${index}`}>Prix Vente TTC</label> */}
                                            <input type="text" id={`t_colonne_${index}`} value={inputSet.pdv || ''} onChange={(e) => handlePDVChange(e, index)} placeholder='Prix Vente TTC' />
                                            <div className='texter-btn'>T</div>
                                        </div>
                                    </div>

                                    <div className='box'>
                                        <div className='container-input'>
                                            {/* <label htmlFor={`grilletaille_${index}`}>Grille Taille</label> */}
                                            <input type="text" id={`v_colonne_${index}`} value={inputSet.tDecli || ''} onChange={(e) => handleTDecliChange(e, index)} placeholder='Grille Taille' />
                                            <div className='texter-btn'>V</div>
                                        </div>

                                        <div className='container-input'>
                                            {/* <label htmlFor={`grillecouleur_${index}`}>Grille Couleur</label> */}
                                            <input type="text" id={`w_colonne_${index}`} value={inputSet.cDecli || ''} onChange={(e) => handleTDecliChange(e, index)} placeholder='Grille Couleur' />
                                            <div className='texter-btn'>W</div>
                                        </div>

                                        <div className='container-input'>
                                            {/* <label htmlFor={`grillematiere_${index}`}>Grille Matière</label> */}
                                            <input type="text" id={`x_colonne_${index}`} value={inputSet.mDecli || ''} onChange={(e) => handleTDecliChange(e, index)} placeholder='Grille Matière' />
                                            <div className='texter-btn'>X</div>
                                        </div>
                                    </div>

                                    <div className='box'>
                                        <div className='container-input blocked'>
                                            {/* <label htmlFor={`methodevalorisationstock_${index}`}>Méthode valorisation Stock</label> */}
                                            <input type="text" id={`u_colonne_${index}`} placeholder='Méthode valorisation Stock' />
                                            <div className='texter-btn'>U</div>
                                        </div>

                                        <div className='container-input'>
                                            <select
                                                name={`y_colonne_${index}`}
                                                id={`y_colonne_${index}`}
                                                value={inputSet.selectedOptionEcategory || ''}
                                                onChange={(e) => handleSelectChangeEcategory(e, index)}
                                            >
                                                <option value="">Assotiation 1</option>
                                                {inputSet.ecategoryFiltered.map((ecategory) => (
                                                    <option key={ecategory.id} value={ecategory.ecategory}>
                                                        {ecategory.name}
                                                    </option>
                                                ))}
                                            </select>
                                            <div className='texter-btn'>Y</div>
                                        </div>

                                        <div className='container-input'>
                                            <select
                                                name={`z_colonne_${index}`}
                                                id={`z_colonne_${index}`}
                                                value={inputSet.selectedOptionEcategory2 || ''}
                                                onChange={(e) => handleSelectChangeEcategory2(e, index)}
                                            >
                                                <option value="">Assotiation 2</option>
                                                {inputSet.ecategory2Filtered.map((ecategory) => (
                                                    <option key={ecategory.id} value={ecategory.ecategory}>
                                                        {ecategory.name}
                                                    </option>
                                                ))}
                                            </select>
                                            <div className='texter-btn'>Z</div>
                                        </div>

                                        <div className='container-input'>
                                            <select
                                                name={`aa_colonne_${index}`}
                                                id={`aa_colonne_${index}`}
                                                value={inputSet.selectedOptionEcategory3 || ''}
                                                onChange={(e) => handleSelectChangeEcategory3(e, index)}
                                            >
                                                <option value="">Assotiation 3</option>
                                                {inputSet.ecategory3Filtered.map((ecategory) => (
                                                    <option key={ecategory.id} value={ecategory.ecategory}>
                                                        {ecategory.name}
                                                    </option>
                                                ))}
                                            </select>
                                            <div className='texter-btn'>AA</div>
                                        </div>

                                        <div className='container-input'>
                                            <select
                                                name={`ab_colonne_${index}`}
                                                id={`ab_colonne_${index}`}
                                                value={inputSet.selectedOptionEcategory4 || ''}
                                                onChange={(e) => handleSelectChangeEcategory4(e, index)}
                                            >
                                                <option value="">Assotiation 4</option>
                                                {inputSet.ecategory4Filtered.map((ecategory) => (
                                                    <option key={ecategory.id} value={ecategory.ecategory}>
                                                        {ecategory.name}
                                                    </option>
                                                ))}
                                            </select>
                                            <div className='texter-btn'>AB</div>
                                        </div>

                                        <div className='container-input'>
                                            <select
                                                name={`ac_colonne_${index}`}
                                                id={`ac_colonne_${index}`}
                                                value={inputSet.selectedOptionEcategory5 || ''}
                                                onChange={(e) => handleSelectChangeEcategory5(e, index)}
                                            >
                                                <option value="">Assotiation 5</option>
                                                {inputSet.ecategory5Filtered.map((ecategory) => (
                                                    <option key={ecategory.id} value={ecategory.ecategory}>
                                                        {ecategory.name}
                                                    </option>
                                                ))}
                                            </select>
                                            <div className='texter-btn'>AC</div>
                                        </div>


                                        <div className='container-input'>
                                            {/* <label htmlFor={`ecategorieclubspardefaut_${index}`}>e_catégorie clubs par défaut</label> */}
                                            <input type="text" id={`ad_colonne_${index}`} placeholder='E-Catégorie_clubs_par_défaut' value={inputSet.ecategory || ''} onChange={(e) => handleSelectChangeFamily(e, index)} />
                                            <div className='texter-btn'>AD</div>
                                        </div>

                                        <div className='container-input'>
                                            {/* <label htmlFor={`prixconseille_${index}`}>Prix conseillé</label> */}
                                            <input type="text" id={`ae_colonne_${index}`} value={inputSet.pdv || ''} onChange={(e) => handlePDVChange(e, index)} placeholder='Prix Vente TTC' />
                                            <div className='texter-btn'>AE</div>
                                        </div>
                                    </div>
                                </>
                            )}

                        </div>
                    ))}


                    <div className='end-step'>
                        <button type="button" className="btn-event" onClick={addInputSet}><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-plus" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#3C3C53" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M12 5l0 14" />
                            <path d="M5 12l14 0" />
                        </svg></button>
                        <button type="button" onClick={resetForm}><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-trash" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#3C3C53" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M4 7l16 0" />
                            <path d="M10 11l0 6" />
                            <path d="M14 11l0 6" />
                            <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                            <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                        </svg></button>
                        {/* <button type="button" className="btn-event" onClick={(event) => SubmitToCSVtest(event)}>Créer</button> */}
                    </div>
                </form>



            </div>

        </div>
    );
}

export default SelectedDP;



/*
<div className='block-inputs'>
            <div className='container-input'>
                <select name={`Family_${index}`} id={`f_colonne_${index}`} onChange={handleSelectChangeFamily}>
                    {Family.map(option => (
                        <option key={option.id} value={option.value}>{option.name}</option>
                    ))}
                </select>
            </div>
        </div>
*/