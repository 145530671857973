import React, { useEffect, useState } from 'react';

import { Link, useLocation } from 'react-router-dom';
import Navbar from '../components/navbar';
import NavbarTop from '../components/navbar-top';



function Home() {
  const location = useLocation();

  const username = sessionStorage.getItem("username");
  
  const isAuthenticated = !!username;

  const handleLogout = () => {
    // Effacer les données d'authentification
    sessionStorage.removeItem("authToken");
    sessionStorage.removeItem("username");
    sessionStorage.removeItem("isAuthenticated");

    // Rediriger vers la page de connexion ou la page d'accueil
    window.location.reload();
  };

  return (
    <>
      <NavbarTop />
      <div className='dashboard-container'>
        <Navbar />

        {/* Ajoutez ici le contenu principal du dashboard à droite */}
        <div className="container-home">
          <div className="container-home-content">
            {isAuthenticated ? (
            <div className="bloc">
            <div>
            <h1>Welcome home {username}!</h1>
            <p>It's good to see you again.</p>
            </div>
            <img src='welcome.svg'></img>
          </div>
          ) : (
            <div className="bloc">
              <div>
              <h1 className='notconnected'>You are not connected!</h1>
              <p>To get all access, please logIn.</p>
              <Link to="/login" className="btn-connect">LogIn</Link>
              </div>
              <img src='offline.svg'></img>
            </div>
          )}
          </div>
        </div>

        <div className="container-home">
          <div className="container-home-content">
          <div className="bloc">
            <div>
            <h1>Soon..</h1>
            <p>Voose x Mailjet</p>
            </div>
          </div>
          </div>
        </div>

      </div>
    </>
  );
}

export default Home;
