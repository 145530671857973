import SelectedDP from '../components/selection_dp';
import NavbarTop from '../components/navbar-top';

function Create() {


  return (
    <>
      <NavbarTop />
      <SelectedDP />
    </>
  );
}

export default Create;
