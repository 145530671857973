import React, { useState } from 'react';

function Crawler() {
    const [url, setUrl] = useState('');
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchResults = async () => {
        if (!url) {
            alert('Please enter a URL');
            return;
        }

        setLoading(true);
        try {
            const response = await fetch(`https://api.voose.fr/crawl?url=${encodeURIComponent(url)}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setResults(data);
        } catch (error) {
            console.error('Fetch error:', error);
            alert('An error occurred while fetching the data.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <h1>Product Crawler</h1>
            <input
                type="text"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                placeholder="Enter product URL"
            />
            <button onClick={fetchResults} disabled={loading}>
                {loading ? 'Crawling...' : 'Start Crawling'}
            </button>
            <table>
                <thead>
                    <tr>
                        <th>URL</th>
                        <th>Has Image</th>
                        <th>Has Description</th>
                        <th>Has Promotion</th>
                    </tr>
                </thead>
                <tbody>
                    {results.map((result, index) => (
                        <tr key={index}>
                            <td>{result.url}</td>
                            <td>{result.hasImage ? 'Yes' : 'No'}</td>
                            <td>{result.hasDescription ? 'Yes' : 'No'}</td>
                            <td>{result.hasPromotion ? 'Yes' : 'No'}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default Crawler;