// LoadingPage.js
import React from 'react';

function LoadingPage() {
  return (
    <div className="loading-page">
      <h2>Chargement...</h2>
      <p>Votre contenu est en cours de chargement, veuillez patienter.</p>
      {/* Vous pouvez ajouter ici une animation de chargement si vous le souhaitez */}
    </div>
  );
}

export default LoadingPage;
