import React from 'react';
import { Navigate } from 'react-router-dom';

// Simule une fonction de vérification de connexion
// Vous devriez remplacer ceci par votre logique d'authentification
function isAuthenticated() {
  // Par exemple, vérifiez un token dans le localStorage
  return sessionStorage.getItem("isAuthenticated") === "true";
}

const ProtectedRoute = ({ children }) => {
  if (!isAuthenticated()) {
    // Si l'utilisateur n'est pas connecté, redirige vers /login
    return <Navigate to="/login" />;
  }

  return children;
};

export default ProtectedRoute;
