// LabelGenerator.js
import React, { useState } from 'react';
import '../css/TagPromo.css'; // Si vous choisissez de séparer les styles dans un fichier CSS
import Navbar from '../components/navbar';
import NavbarTop from '../components/navbar-top'

function LabelGenerator() {
    const [products, setProducts] = useState([]);
    const [newProduct, setNewProduct] = useState({
        title: '',
        selling_price: '',
        public_price: '',
        is_woman: false,
        is_prixgp: false
    });

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setNewProduct({
            ...newProduct,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const addProduct = () => {
        if (newProduct.title.trim() && products.length < 9) {
            setProducts([...products, { ...newProduct }]);
            setNewProduct({ title: '', selling_price: '', public_price: '', is_woman: false, is_prixgp: false });
        }
    };

    const removeProduct = (index) => {
        setProducts(products.filter((_, i) => i !== index));
    };

    return (
        <div className='dashboard-container'>
            <NavbarTop />
            <Navbar />

            <div id="app" className='app'>
                <div id="form" className="box2">
                    <input
                        placeholder="Article"
                        autoFocus
                        autoComplete="off"
                        size="40"
                        name="title"
                        value={newProduct.title}
                        onChange={handleInputChange}
                        id="product_title"
                    />
                    <input
                        placeholder="Prix de vente"
                        autoComplete="off"
                        size="12"
                        name="selling_price"
                        value={newProduct.selling_price}
                        onChange={handleInputChange}
                    />
                    <input
                        placeholder="Prix barré"
                        autoComplete="off"
                        size="12"
                        name="public_price"
                        value={newProduct.public_price}
                        onChange={handleInputChange}
                    />
                    <input
                        type="checkbox"
                        name="is_woman"
                        checked={newProduct.is_woman}
                        onChange={handleInputChange}
                    /><label>Produit femme?</label>
                    <input
                        type="checkbox"
                        name="is_prixgp"
                        checked={newProduct.is_prixgp}
                        onChange={handleInputChange}
                    /><label>Prix GP?</label>
                </div>

                <div className='box2'>
                    <button onClick={addProduct}>Ajouter</button>

                    {products.length > 0 && (
                        <div id="print_btn_container" className="no_print">
                            <button onClick={() => window.print()}>Imprimer</button>
                        </div>
                    )}
                </div>


                <table id="etiquettes">
                    {products.map((product, index) => (
                        <tr key={index} onClick={() => removeProduct(index)}>
                            <td><div className={`title ${product.is_woman ? 'pink_title' : ''}`}>{product.title}</div></td>
                            <td className="acenter">
                                <span className="price public_price">{product.public_price}</span>
                                <br />
                                <span className={`price selling_price ${product.is_woman ? 'pink' : ''}`}>{product.selling_price}</span>
                            </td>
                            <td className="prix_gp_container">
                                {product.is_prixgp && <img className="img_prix_gp" src="../img/logo_gp.png" alt="Prix Golf Passion" />}
                            </td>
                        </tr>
                    ))}
                </table>
            </div>
        </div>
    );
}

export default LabelGenerator;
