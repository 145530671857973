import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AdaptiveLoader from './AdaptiveLoader';
import Home from './views/home';
import Config from './views/config';
import Create from './views/create';
import TagPromo from './views/tag-promo';
import Documentation from './views/docs';
import Login from './views/auth/login';
import Error404 from './views/error/error404';
import MailVoose from './views/mailvoose';
import News from './views/news';
import Carte from './views/carte';
import Crawler from './views/crawler';
import ProtectedRoute from './protectedroute'; // Assurez-vous que le chemin d'importation est correct

import './App.css';

function App() {

  return (
    <BrowserRouter>
      <AdaptiveLoader>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/create" element={<Create />} />
          <Route path="/tag" element={<TagPromo />} />
          <Route path="/carte" element={<Carte />} />
          <Route path="/mailvoose" element={<MailVoose />} />
          <Route path='/docs' element={<Documentation />} />
          <Route path='/whats-new' element={<News />} />
          <Route path='/crawler' element={<Crawler />} />
          <Route path="/config" element={
            <ProtectedRoute>
              <Config />
            </ProtectedRoute>
          } />

          <Route path="/login" element={<Login />} />

          <Route path="*" element={<Error404 />} />
        </Routes>
      </AdaptiveLoader>
    </BrowserRouter>
  );
}

export default App;
