import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        setError(''); // Réinitialiser les erreurs précédentes
    
        try {
            const response = await fetch(`https://api.voose.fr/api/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            });
    
            if (!response.ok) {
                throw new Error('Login failed');
            }
    
            const data = await response.json();
            // Utiliser sessionStorage au lieu de localStorage
            sessionStorage.setItem("username", data.username);
            sessionStorage.setItem("email", data.email);
            sessionStorage.setItem("authToken", data.token);
            sessionStorage.setItem("isAuthenticated", "true");
            navigate('/config'); // Redirige l'utilisateur vers /config
    
            navigate('/');
        } catch (error) {
            setError('Login failed. Please check your username and password.');
            // Gérer l'erreur de connexion ici
        }
    };
    

    return (
        <div className='login'>
            <div className="login-container">
                <form onSubmit={handleLogin}>
                    <div className="addons">
                    <Link to="/"><img src="iconvoose.png" alt="Voose icon" /></Link>
                    <h1>LogIn</h1>
                    </div>
                    <div className="addons-content">
                    <div className='content'>
                        <label>Email</label>
                        <input
                            type="text"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className='content'>
                        <label>Password</label>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>

                    </div>
                    {error && <p className='error'>{error}</p>}
                    <button type="submit">Login</button>
                    <div className='flexing-login'>
                        <div>
                        <img src='profile.svg'></img>
                        </div>
                        <div>
                            <h4>Unable to connect?</h4>
                            <p>If you're experiencing difficulty connecting, please don't hesitate to reach out to our team for assistance. We're here to help ensure your experience is seamless and trouble-free. You can contact us through <a href='mailto:pierre@legouix.dev'> Contact team.</a></p>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Login;
