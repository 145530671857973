// AdaptiveLoader.js
import React, { useState, useEffect } from 'react';
import LoadingPage from './components/LoadingPage'; // Assurez-vous que le chemin d'importation est correct

const AdaptiveLoader = ({ children }) => {
  const [isSlowConnection, setIsSlowConnection] = useState(false);

  useEffect(() => {
    if (navigator.connection) {
      const slowConnections = ['slow-2g', '2g'];
      const updateConnectionStatus = () => {
        setIsSlowConnection(slowConnections.includes(navigator.connection.effectiveType));
      };

      updateConnectionStatus(); // Vérifiez immédiatement à l'initialisation du composant
      navigator.connection.addEventListener('change', updateConnectionStatus);

      return () => {
        navigator.connection.removeEventListener('change', updateConnectionStatus);
      };
    }
  }, []);

  // Si la connexion est lente, afficher la page de chargement au lieu des enfants directement
  return isSlowConnection ? <LoadingPage /> : children;
};

export default AdaptiveLoader;
