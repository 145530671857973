import React, { useEffect, useState } from 'react';
import axios from 'axios';

function ChangeDeclinaison({ tableName }) {
    const [tableData, setTableData] = useState({ columns: [], rows: [] });
    const [newData, setNewData] = useState({});
    const [editingId, setEditingId] = useState(null);
    const [editFormData, setEditFormData] = useState({});

    useEffect(() => {
        axios.get(`${process.env.VOOSE_API_URL}/config/${tableName}`)
            .then(res => {
                setTableData({
                    columns: res.data.columns || [],
                    rows: res.data.rows || []
                });
            })
            .catch(err => console.error(err));
    }, [tableName]);

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setNewData(prev => ({ ...prev, [name]: value }));
    };

    const handleEditFormChange = (e) => {
        const { name, value } = e.target;
        setEditFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleAdd = (e) => {
        e.preventDefault();
        axios.post(`https://api.voose.fr/config/`, { tableName, data: newData })
            .then(() => {
                // Recharger les données de la table
                window.location.reload();
            })
            .catch(err => console.error(err));
    };

    const handleEdit = (row) => {
        setEditingId(row.id);
        setEditFormData(row);
    };

    const handleUpdate = (id, e) => {
        e.preventDefault();
        axios.patch(`https://api.voose.fr/config/${tableName}/${id}`, editFormData)
            .then(() => {
                // Recharger les données de la table

                window.location.reload();
                setEditingId(null);
            })
            .catch(err => console.error(err));
    };

    const handleDelete = (id) => {
        axios.delete(`https://api.voose.fr/config/${tableName}/${id}`)
            .then(() => {
                // Recharger les données de la table

                window.location.reload();
            })
            .catch(err => console.error(err));
    };

    return (
        <div className='container-settings'>
            <h1>Gestion de la table: {tableName}</h1>
            <div className='table-responsive'>
                <table className='table'>
                    <thead>
                        <tr>
                            {tableData.columns.map((column, index) => (
                                <th key={index}>{column.Field}</th>
                            ))}
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.rows.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                {tableData.columns.map((column, columnIndex) => (
                                    <td key={`${rowIndex}-${columnIndex}`}>
                                        {editingId === row.id ? (
                                            <input
                                                name={column.Field}
                                                value={editFormData[column.Field]}
                                                onChange={handleEditFormChange}
                                            />
                                        ) : (
                                            row[column.Field]
                                        )}
                                    </td>
                                ))}
                                <td>
                                    {editingId === row.id ? (
                                        <button onClick={(e) => handleUpdate(row.id, e)}><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-device-floppy" width="23" height="23" viewBox="0 0 24 24" stroke-width="1.5" stroke="#fff" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M6 4h10l4 4v10a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2" />
                                            <path d="M12 14m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                                            <path d="M14 4l0 4l-6 0l0 -4" />
                                        </svg></button>
                                    ) : (
                                        <button onClick={() => handleEdit(row)}><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-edit" width="23" height="23" viewBox="0 0 24 24" stroke-width="1.5" stroke="#fff" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" />
                                            <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" />
                                            <path d="M16 5l3 3" />
                                        </svg></button>
                                    )}
                                    <button onClick={() => handleDelete(row.id)}><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-trash" width="23" height="23" viewBox="0 0 24 24" stroke-width="1.5" stroke="#fff" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M4 7l16 0" />
                                        <path d="M10 11l0 6" />
                                        <path d="M14 11l0 6" />
                                        <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                        <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                    </svg></button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <form onSubmit={handleAdd}>
                    {tableData.columns.map((column, index) => (
                        <input
                            key={index}
                            name={column.Field}
                            value={newData[column.Field] || ''}
                            onChange={handleFormChange}
                            placeholder={column.Field}
                        />
                    ))}
                    <button type="submit">Ajouter</button>
                </form>
            </div>
        </div>
    );
}

export default ChangeDeclinaison;
