import React from 'react';
import { useState } from 'react'; // Importer useState
import { Link, useLocation } from 'react-router-dom';
import "typeface-montserrat";
import { Navigate } from 'react-router-dom';

function NavbarTop() {
  const location = useLocation();
  const [showPopup, setShowPopup] = useState(false); // Ajout de cet état
  const [showMAJPopup, setShowMAJPopup] = useState(false); // Ajout de cet état
  const [showSettingsPopup, setShowSettingsPopup] = useState(false); // Ajout de cet état
  const [animateOut, setAnimateOut] = useState(false);
  const [settingsContent, setSettingsContent] = useState('Default');  // État pour gérer le contenu des paramètres

  // Fonction pour changer le contenu des paramètres
  const handleComponentChange = (componentName) => {
    setSettingsContent(componentName);
  };


  const togglePopup = () => {
    if (showPopup) {
      // Commencer l'animation de sortie
      setAnimateOut(true);
      // Attendre la fin de l'animation avant de masquer la popup
      setTimeout(() => {
        setShowPopup(false);
        setAnimateOut(false); // Réinitialiser l'état pour la prochaine ouverture
      }, 500); // Assurez-vous que cela correspond à la durée de votre animation CSS
    } else {
      setShowPopup(true);
    }
  };

  const toggleMAJPopup = () => {
    if (showMAJPopup) {
      // Commencer l'animation de sortie
      setAnimateOut(true);
      // Attendre la fin de l'animation avant de masquer la popup
      setTimeout(() => {
        setShowMAJPopup(false);
        setAnimateOut(false); // Réinitialiser l'état pour la prochaine ouverture
      }, 500); // Assurez-vous que cela correspond à la durée de votre animation CSS
    } else {
      setShowMAJPopup(true);
    }
  };

  const toggleSettingsPopup = () => {
    if (showSettingsPopup) {
      // Commencer l'animation de sortie
      setAnimateOut(true);
      // Attendre la fin de l'animation avant de masquer la popup
      setTimeout(() => {
        setShowSettingsPopup(false);
        setAnimateOut(false); // Réinitialiser l'état pour la prochaine ouverture
      }, 500); // Assurez-vous que cela correspond à la durée de votre animation CSS
    } else {
      setShowSettingsPopup(true);
    }
  };

  const username = sessionStorage.getItem("username");
  const email = sessionStorage.getItem("email");

  const isAuthenticated = !!username;
  // Supprimer le slash initial et diviser l'URL en segments
  const pathSegments = location.pathname.slice(1).split('/');

  const handleLogout = () => {
    // Effacer les données d'authentification
    sessionStorage.removeItem("authToken");
    sessionStorage.removeItem("username");
    sessionStorage.removeItem("isAuthenticated");

    // Rediriger vers la page de connexion ou la page d'accueil
    window.location.reload();
  };

  return (
    <nav>
      <Link to="/"><img src="iconvoose.png" alt="Voose icon" /></Link>

      <div className='nav-top-content'>
        {/* Afficher les segments de l'URL avec des slashs */}
        <div className='flexingnav'>
          {pathSegments.map((segment, index) => (
            <React.Fragment key={index}>
              <p className='slashing'> / </p> {/* Ajouter un slash avant chaque segment */}
              <p>{segment}</p>
            </React.Fragment>
          ))}
        </div>

        <div className='flexingnavtop'>
          <Link onClick={toggleMAJPopup}>
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-bell" width="23" height="23" viewBox="0 0 24 24" stroke-width="1.5" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M10 5a2 2 0 1 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6" />
              <path d="M9 17v1a3 3 0 0 0 6 0v-1" />
            </svg>
          </Link>

          {isAuthenticated ? (
            // Icône d'utilisateur (connecté)        
            <Link onClick={togglePopup}>
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-user-cog" width="23" height="23" viewBox="0 0 24 24" stroke-width="1.5" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
                <path d="M6 21v-2a4 4 0 0 1 4 -4h2.5" />
                <path d="M19.001 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                <path d="M19.001 15.5v1.5" />
                <path d="M19.001 21v1.5" />
                <path d="M22.032 17.25l-1.299 .75" />
                <path d="M17.27 20l-1.3 .75" />
                <path d="M15.97 17.25l1.3 .75" />
                <path d="M20.733 20l1.3 .75" />
              </svg></Link>
          ) : (
            // Icône de connexion (non connecté)
            <Link to="/login">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-user" width="23" height="23" viewBox="0 0 24 24" stroke-width="1.5" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
                <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
              </svg>
            </Link>
          )}

        </div>
      </div>

      {showPopup && (
        <div className={`popup-account ${animateOut ? 'slide-out' : 'slide-in'}`}>
          <div className="popup-inner">
            <img src='profile.svg'></img>
            <h3>{username}</h3>
            <span>{email}</span>

            <Link onClick={toggleSettingsPopup} ><button>Settings</button></Link>
            <button onClick={handleLogout}>Déconnexion</button>
          </div>
        </div>
      )}

      {showMAJPopup && (
        <div className={`popup-account ${animateOut ? 'slide-out' : 'slide-in'}`}>
          <div className="popup-inner">
            <div className="flexing-popup">
              <img src='profile.svg'></img>
              <div className=''>
                <h3>V.1.1 Was just arrived!</h3>
                <p>Optimizations on modals and more comp..</p>
                <Link to="/whats-new">Learn more</Link>
              </div>
            </div>

          </div>
        </div>
      )}




      {showSettingsPopup && (
        <div className='bkg-settings'>
          <div className={`popup-account-settings ${animateOut ? 'slide-out' : 'slide-in'}`}>
            <div className="popup-inner-settings">

              <div className='left-box-settings'>
                <div className='settings-navbar'>
                  <div className='link-btn-settings' onClick={() => handleComponentChange('Default')}><p>Global</p></div>
                  <div className='link-btn-settings' onClick={() => handleComponentChange('ChangeAPI')}><p>API</p></div>
                  <Link to="/whats-new"><div className='link-btn-settings'>Updates</div></Link>
                </div>
              </div>

              <div className='container-settings'>
                <div className='flexing'>
                  <h2>Settings</h2>
                  <button onClick={toggleSettingsPopup}>close</button>
                </div>

                {settingsContent === 'Default' &&
                  <div className='text'>
                    <h3>Modify my account</h3>
                    <p>Make you sure it's you'r account before modifications. <a href='#' onClick={handleLogout}>Isn't ? Switch account.</a></p>
                    <form>
                      <div className='col'>
                      <label>Email</label>
                      <input type="email" placeholder="Email" />
                      </div>
                      <div className='col'>
                      <label>Password</label>
                      <input type="password" placeholder="Password" />
                      </div>
                      <button>Save</button>
                    </form>
                  </div>}





                {settingsContent === 'ChangeAPI' &&
                <div className='text'>
                <h3>API'S</h3>
                <p>Here's the list of api's avaible:</p>

                <span>Voosh.. Nothing appear...</span>
              </div>
              }
              </div>

            </div>
          </div>
        </div>
      )}
    </nav>
  );
}

export default NavbarTop;
