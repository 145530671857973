// LabelGenerator.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../css/TagPromo.css'; // Si vous choisissez de séparer les styles dans un fichier CSS
import Navbar from '../components/navbar';
import NavbarTop from '../components/navbar-top'

function MailVoose() {
    const [contacts, setContacts] = useState([]);

  useEffect(() => {
    fetchContacts();
  }, []);

  const fetchContacts = () => {
    axios.get('https://api.voose.fr/api/contacts')
      .then(response => {
        console.log(response.data); // Vérifiez la structure ici
        setContacts(response.data.Data);
      })
      .catch(error => console.error('Error fetching contacts', error));
};


  const deleteContact = (contactId) => {
    axios.delete(`https://api.voose.fr/api/contacts/${contactId}`)
      .then(() => {
        alert('Contact deleted!');
        fetchContacts();  // Re-fetch contacts after deletion
      })
      .catch(error => {
        alert('Failed to delete contact');
        console.error('Error:', error);
      });
  };

  const updateContact = (contactId, newEmail) => {
    axios.put(`https://api.voose.fr/api/contacts/${contactId}`, { Email: newEmail })
      .then(() => {
        alert('Contact updated!');
        fetchContacts();  // Re-fetch contacts after update
      })
      .catch(error => {
        alert('Failed to update contact');
        console.error('Error:', error);
      });
  };

    return (
        <div className='dashboard-container'>
            <NavbarTop />
            <Navbar />



            <div className='box'>
      {Array.isArray(contacts) && contacts.map(contact => (
        <div key={contact.ID}>
          <span>{contact.Email}</span>
          <button onClick={() => updateContact(contact.ID, 'newemail@example.com')}>Update Email</button>
          <button onClick={() => deleteContact(contact.ID)}>Delete</button>
        </div>
      ))}
    </div>

        </div>
    );
}

export default MailVoose;
