import React, { useEffect, useState } from 'react';

import { Link, useLocation } from 'react-router-dom';
import Navbar from '../components/navbar';
import NavbarTop from '../components/navbar-top';

function Documentation() {
    const location = useLocation();

    const username = sessionStorage.getItem("username");

    const isAuthenticated = !!username;

    const handleLogout = () => {
        // Effacer les données d'authentification
        sessionStorage.removeItem("authToken");
        sessionStorage.removeItem("username");
        sessionStorage.removeItem("isAuthenticated");

        // Rediriger vers la page de connexion ou la page d'accueil
        window.location.reload();
    };

    return (
        <>
            <NavbarTop />
            <div className='dashboard-container'>
                <Navbar />

                {/* Ajoutez ici le contenu principal du dashboard à droite */}
                <div className="container-home">
                    <h1>News</h1>

                    <div className='doc-content'>
                        <h2>Version V.1.1</h2>

                        <p>We're excited to announce that we have recently implemented a series of optimizations across our application to enhance performance and user experience. As part of our commitment to providing a seamless and efficient service, we have conducted an extensive review and optimization of our core functionalities. This effort has significantly improved the speed and responsiveness of our application, ensuring that tasks are completed more swiftly and smoothly.</p>
                        <p>Additionally, we have addressed several bugs that were identified during the article creation process. These issues, ranging from minor glitches that affected the user interface to more complex problems impacting data integrity and storage, have been thoroughly resolved. Our development team has worked diligently to ensure that these corrections not only fix the immediate issues but also enhance the overall stability and reliability of the platform.</p>
                        <p>We believe these improvements will make a noticeable difference in your experience, particularly when creating and managing articles. Our goal is always to empower our users with the best tools and a robust platform that supports all your needs effectively.</p>
                        <br/>
                        <br/>
                        
                        <p>- Added Settings components</p>
                        <p>- Inclued optimizations into modal's</p>
                        <p>- Added Code text into Declinaisons</p>
                        <p>- Optimization in Articles</p>
                        <p>- Added new components (NameTag)</p>

                        <br/>
                        <p>Thank you for your continued support and feedback, which are invaluable to our ongoing efforts to improve and evolve.</p>
                    </div>


                </div>
            </div>
        </>
    );
}

export default Documentation;