import React, { useState, useEffect } from 'react';

function SelectionDPtest({ inputSet, onChange }) {
    const [tableName, setTableName] = useState('');
    const [data, setData] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [checkedOptions, setCheckedOptions] = useState(inputSet.checkedOptions || { taille: [], couleur: [], matière: [] });
    const [combinations, setCombinations] = useState(inputSet.combinations || []); // Initialiser avec les combinaisons existantes

    // Synchroniser les valeurs de déclinaison avec l'inputSet
    useEffect(() => {
        setCheckedOptions(inputSet.checkedOptions || { taille: [], couleur: [], matière: [] });
        setCombinations(inputSet.combinations || []); // Synchroniser les combinaisons
    }, [inputSet]);

    const fetchData = async () => {
        if (!tableName) {
            alert('Veuillez entrer le nom d\'une table');
            return;
        }
        setLoading(true);
        setError('');
        try {
            console.log("API URL:", process.env.VOOSE_API_URL); // Vérifiez si l'URL est affichée correctement
            const response = await fetch(`https://api.voose.fr/table/${tableName}`);
            if (!response.ok) {
                throw new Error(`Erreur: ${response.status} ${response.statusText}`);
            }
            const fetchedData = await response.json();

            // Créer un objet pour stocker les valeurs uniques par catégorie
            const categories = fetchedData.reduce((acc, { prefix, code, libelle }) => {
                if (!acc[prefix]) {
                    acc[prefix] = [];
                }
                acc[prefix].push({ code, libelle });
                return acc;
            }, {});

            setData(categories);
            setShowPopup(true);
        } catch (error) {
            setError("Erreur lors de la récupération des données: " + error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleCheckboxChange = (category, code, libelle) => {
        setCheckedOptions(prev => {
            const categoryOptions = prev[category] || [];
            const optionIndex = categoryOptions.findIndex(option => option.code === code);

            if (optionIndex > -1) {
                // Si l'option est déjà sélectionnée, la supprimer
                return {
                    ...prev,
                    [category]: [
                        ...categoryOptions.slice(0, optionIndex),
                        ...categoryOptions.slice(optionIndex + 1)
                    ]
                };
            } else {
                // Sinon, ajouter l'option
                return {
                    ...prev,
                    [category]: [...categoryOptions, { code, libelle }]
                };
            }
        });
    };

    const generateCombinations = () => {
        const tailles = checkedOptions.taille.length > 0 ? checkedOptions.taille : [{ code: '', libelle: '' }];
        const couleurs = checkedOptions.couleur.length > 0 ? checkedOptions.couleur : [{ code: '', libelle: '' }];
        const matières = checkedOptions.matière.length > 0 ? checkedOptions.matière : [{ code: '', libelle: '' }];

        const newCombinations = [];
        tailles.forEach(tailleOption => {
            couleurs.forEach(couleurOption => {
                matières.forEach(matièreOption => {
                    newCombinations.push({
                        taille: tailleOption.code,
                        couleur: couleurOption.code,
                        matière: matièreOption.code,
                        tailleLibelle: tailleOption.libelle,
                        couleurLibelle: couleurOption.libelle,
                        matièreLibelle: matièreOption.libelle
                    });
                });
            });
        });

        setCombinations(newCombinations);
        // Mettre à jour l'inputSet avec les nouvelles options
        onChange({ ...inputSet, checkedOptions, combinations: newCombinations }); // Mettre à jour les combinaisons
    };

    return (
        <div>
            <div className='container-input'>
                <input
                    type="text"
                    value={tableName}
                    onChange={(e) => setTableName(e.target.value)}
                    placeholder="Entrez le nom de la table"
                />
                <button className='texter-btn' type='button' onClick={fetchData}>
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-search" width="23" height="23" viewBox="0 0 24 24" stroke-width="1.5" stroke="#3C3C53" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" />
                    <path d="M21 21l-6 -6" />
                </svg>
                </button>
            </div>

            {loading && <p>Chargement...</p>}
            {error && <p style={{ color: 'red' }}>{error}</p>}

            {showPopup && (
                <div className='popup'>
                    <h2>Options de {tableName}</h2>
                    {Object.entries(data).map(([category, options]) => (
                        <div key={category}>
                            <h3>{category.charAt(0).toUpperCase() + category.slice(1)}</h3>
                            <div className='popup-content'>
                                {options.map(option => (
                                    <li key={option.code}>
                                        <input
                                            type="checkbox"
                                            checked={checkedOptions[category] ? checkedOptions[category].some(checkedOption => checkedOption.code === option.code) : false}
                                            onChange={() => handleCheckboxChange(category, option.code, option.libelle)}
                                        />
                                        {option.libelle}
                                        <span className='code-declinaison'> - ({option.code})</span>
                                    </li>
                                ))}
                            </div>
                        </div>
                    ))}
                    <button type='button' onClick={generateCombinations}>Générer les combinaisons</button>
                    <button type='button' onClick={() => setShowPopup(false)}>Fermer</button>
                </div>
            )}

            {combinations.map((combo, index) => (
                <div key={index} className="input-row">
                    <div className='container-input blocked'>
                        {/* <label htmlFor={`c_${index}`}>Code Barre</label> */}
                        <input type="text" id={`a_colonne_${index}`} value="DE" />
                        <div className='texter-btn'>A</div>
                    </div>

                    <div className='container-input blocked'>
                        {/* <label htmlFor={`c_${index}`}>Code Barre</label> */}
                        <input type="text" id={`b_colonne_${index}`} value="" />
                        <div className='texter-btn'>B</div>
                    </div>

                    <div className='container-input blocked'>
                        {/* <label htmlFor={`c_${index}`}>Code Barre</label> */}
                        <input type="text" id={`c_colonne_${index}`} value="<A générer>" />
                        <div className='texter-btn'>C</div>
                    </div>

                    <div className='container-input blocked'>
                        {/* <label htmlFor={`commercialise_${index}`}>Commercialisé</label> */}
                        <input
                            type="checkbox"
                            id={`l_colonne_${index}`}
                            value="Vrai"
                            checked={true}
                        />
                        <div className='texter-btn'>L</div>
                    </div>

                    <div className='container-input blocked'>
                        {/* <label htmlFor={`commercialise_${index}`}>Commercialisé</label> */}
                        <input
                            type="checkbox"
                            id={`m_colonne_${index}`}
                            value="Vrai"
                            checked={true}
                        />
                        <div className='texter-btn'>M</div>
                    </div>

                    <div className='container-input blocked'>
                        {/* <label htmlFor={`methodevalorisationstock_${index}`}>Méthode valorisation Stock</label> */}
                        <input type="text" id={`u_colonne_${index}`} value={"Prix moyen pondéré d'achat"} />
                        <div className='texter-btn'>U</div>
                    </div>

                    <div className='container-input blocked'>
                        {/* <label htmlFor={`c_${index}`}>Code Barre</label> */}
                        <input type="text" id={`g_colonne_${index}`} value="" />
                        <div className='texter-btn'>G</div>
                    </div>

                    <div className='container-input blocked'>
                        {/* <label htmlFor={`c_${index}`}>Code Barre</label> */}
                        <input type="text" id={`h_colonne_${index}`} value="" />
                        <div className='texter-btn'>H</div>
                    </div>

                    <div className='container-input blocked'>
                        {/* <label htmlFor={`c_${index}`}>Code Barre</label> */}
                        <input type="text" id={`j_colonne_${index}`} value="" />
                        <div className='texter-btn'>J</div>
                    </div>

                    <div className='container-input blocked'>
                        {/* <label htmlFor={`c_${index}`}>Code Barre</label> */}
                        <input type="text" id={`k_colonne_${index}`} value="" />
                        <div className='texter-btn'>K</div>
                    </div>

                    <div className='container-input blocked'>
                        {/* <label htmlFor={`c_${index}`}>Code Barre</label> */}
                        <input type="text" id={`p_colonne_${index}`} value="" />
                        <div className='texter-btn'>P</div>
                    </div>

                    <div className='container-input blocked'>
                        {/* <label htmlFor={`achatreference_${index}`}>Achat Réference</label> */}
                        <input type="text" id={`q_colonne_${index}`} placeholder='Achat Réference' />
                        <div className='texter-btn'>Q</div>
                    </div>

                    <div className='container-input blocked'>
                        {/* <label htmlFor={`c_${index}`}>Code Barre</label> */}
                        <input type="text" id={`r_colonne_${index}`} value="" />
                        <div className='texter-btn'>R</div>
                    </div>

                    <div className='container-input blocked'>
                        {/* <label htmlFor={`c_${index}`}>Code Barre</label> */}
                        <input type="text" id={`s_colonne_${index}`} value="" />
                        <div className='texter-btn'>S</div>
                    </div>

                    <div className='container-input blocked'>
                        {/* <label htmlFor={`c_${index}`}>Code Barre</label> */}
                        <input type="text" id={`t_colonne_${index}`} value="" />
                        <div className='texter-btn'>T</div>
                    </div>

                    <div className='container-input blocked'>
                        {/* <label htmlFor={`c_${index}`}>Code Barre</label> */}
                        <input type="text" id={`v_colonne_${index}`} value="" />
                        <div className='texter-btn'>V</div>
                    </div>

                    <div className='container-input blocked'>
                        {/* <label htmlFor={`c_${index}`}>Code Barre</label> */}
                        <input type="text" id={`w_colonne_${index}`} value="" />
                        <div className='texter-btn'>W</div>
                    </div>

                    <div className='container-input blocked'>
                        {/* <label htmlFor={`c_${index}`}>Code Barre</label> */}
                        <input type="text" id={`x_colonne_${index}`} value="" />
                        <div className='texter-btn'>X</div>
                    </div>

                    <div className='container-input blocked'>
                        {/* <label htmlFor={`c_${index}`}>Code Barre</label> */}
                        <input type="text" id={`y_colonne_${index}`} value="" />
                        <div className='texter-btn'>Y</div>
                    </div>

                    <div className='container-input blocked'>
                        {/* <label htmlFor={`c_${index}`}>Code Barre</label> */}
                        <input type="text" id={`z_colonne_${index}`} value="" />
                        <div className='texter-btn'>Z</div>
                    </div>

                    <div className='container-input blocked'>
                        {/* <label htmlFor={`c_${index}`}>Code Barre</label> */}
                        <input type="text" id={`aa_colonne_${index}`} value="" />
                        <div className='texter-btn'>AA</div>
                    </div>
                    <div className='container-input blocked'>
                        {/* <label htmlFor={`c_${index}`}>Code Barre</label> */}
                        <input type="text" id={`ab_colonne_${index}`} value="" />
                        <div className='texter-btn'>AB</div>
                    </div>
                    <div className='container-input blocked'>
                        {/* <label htmlFor={`c_${index}`}>Code Barre</label> */}
                        <input type="text" id={`ac_colonne_${index}`} value="" />
                        <div className='texter-btn'>AC</div>
                    </div>
                    <div className='container-input blocked'>
                        {/* <label htmlFor={`c_${index}`}>Code Barre</label> */}
                        <input type="text" id={`ad_colonne_${index}`} value="" />
                        <div className='texter-btn'>AD</div>
                    </div>
                    <div className='container-input blocked'>
                        {/* <label htmlFor={`c_${index}`}>Code Barre</label> */}
                        <input type="text" id={`ae_colonne_${index}`} value="" />
                        <div className='texter-btn'>AE</div>
                    </div>
                    <div className='container-input blocked'>
                        {/* <label htmlFor={`c_${index}`}>Code Barre</label> */}
                        <input type="text" id={`af_colonne_${index}`} value="" />
                        <div className='texter-btn'>AF</div>
                    </div>

                    {/* Détails de la combinaison */}
                    <div className='container-input'>
                    <input type="text" placeholder="Taille" value={combo.tailleLibelle} readOnly />
                        <input type="text" placeholder="Couleur" value={combo.couleurLibelle} readOnly />
                        <input type="text" placeholder="Matière" value={combo.matièreLibelle} readOnly />

                        <input type="hidden" placeholder="Taille" value={combo.taille} id={`e_colonne_${index}`} readOnly />
                        <input type="hidden" placeholder="Couleur" value={combo.couleur} id={`f_colonne_${index}`} readOnly />
                        <input type="hidden" placeholder="Matière" value={combo.matière} id={`i_colonne_${index}`} readOnly />

                        {/* Bouton de suppression */}
                        <button type="button" onClick={() => setCombinations(prev => prev.filter((_, i) => i !== index))}><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-backspace" width="23" height="23" viewBox="0 0 24 24" stroke-width="1.5" stroke="#3C3C53" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M20 6a1 1 0 0 1 1 1v10a1 1 0 0 1 -1 1h-11l-5 -5a1.5 1.5 0 0 1 0 -2l5 -5z" />
                            <path d="M12 10l4 4m0 -4l-4 4" />
                        </svg></button>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default SelectionDPtest;
