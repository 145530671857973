import React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import '../App.css';


function Navbar() {

  return (
      <div className='left-box'>

        <div className='items-center'>
        <Link to="/" className='link-btn'>
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-home" width="23" height="23" viewBox="0 0 24 24" stroke-width="1.5" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M5 12l-2 0l9 -9l9 9l-2 0" />
  <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />
  <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" />
</svg>
            <p>Dashboard</p>
          </Link>


          <Link to="/create" className='link-btn'>
            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-file-plus" width="23" height="23" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M14 3v4a1 1 0 0 0 1 1h4" />
              <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
              <path d="M12 11l0 6" />
              <path d="M9 14l6 0" />
            </svg>
            <p>Articles</p>
          </Link>

          {/* <Link to="/createtest" className='link-btn'>Aller sur la Création</Link> */}

          <Link to="/tag" className='link-btn'>
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-layout-collage" width="23" height="23" viewBox="0 0 24 24" stroke-width="1.5" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z" />
  <path d="M10 4l4 16" />
  <path d="M12 12l-8 2" />
</svg>
            <p>Nametag</p>
          </Link>

          <Link to="/carte" className='link-btn'>
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-layout-collage" width="23" height="23" viewBox="0 0 24 24" stroke-width="1.5" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z" />
  <path d="M10 4l4 16" />
  <path d="M12 12l-8 2" />
</svg>
            <p>Carte</p>
          </Link>

          <Link to="/crawler" className='link-btn'>
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-layout-collage" width="23" height="23" viewBox="0 0 24 24" stroke-width="1.5" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z" />
  <path d="M10 4l4 16" />
  <path d="M12 12l-8 2" />
</svg>
            <p>Scan</p>
          </Link>

          <Link to="/mailvoose" className='link-btn'>
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-background" width="23" height="23" viewBox="0 0 24 24" stroke-width="1.5" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M4 8l4 -4" />
              <path d="M14 4l-10 10" />
              <path d="M4 20l16 -16" />
              <path d="M20 10l-10 10" />
              <path d="M20 16l-4 4" />
            </svg>
            <p>MailVoose</p>
          </Link>
        </div>



        <div className='flexing-end'>
          <div className='gap-end'>
            <Link to="/docs"><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-book" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M3 19a9 9 0 0 1 9 0a9 9 0 0 1 9 0" />
              <path d="M3 6a9 9 0 0 1 9 0a9 9 0 0 1 9 0" />
              <path d="M3 6l0 13" />
              <path d="M12 6l0 13" />
              <path d="M21 6l0 13" />
            </svg>
            </Link>

            <Link to="/config">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-settings" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z" />
                <path d="M9 12a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
              </svg>
            </Link>
            </div>
        </div>
      </div>
  );
}

export default Navbar;
