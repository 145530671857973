import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from '../components/navbar';
import NavbarTop from '../components/navbar-top';
import Papa from 'papaparse';

function Carte() {
    const [groupes, setGroupes] = useState([]); // État pour les groupes
    const [rayon, setRayon] = useState(0);
    const [villeDepart, setVilleDepart] = useState('');
    const [codePostalRecherche, setCodePostalRecherche] = useState('');
    
    // État pour le nouveau groupe
    const [nouveauGroupe, setNouveauGroupe] = useState({
        nom: '',
        prenom: '',
        adresse: '',
        codePostal: '',
        numeroTelephone: '',
        email: ''
    });

    useEffect(() => {
        fetchGroupes(); // Récupérer les groupes
    }, []);

    const fetchGroupes = () => {
        axios.get('json/codes_postaux.json')
            .then(response => {
                setGroupes(response.data.groupes || []); // Mettre à jour l'état avec les groupes
            })
            .catch(error => console.error('Error fetching groupes', error));
    };

    const handleCodePostalChange = (event) => {
        setCodePostalRecherche(event.target.value);
    };

    // Filtrer les groupes en fonction de l'entrée
    const groupesFiltres = codePostalRecherche.trim() === '' 
    ? groupes // Afficher tous les groupes si le champ est vide
    : groupes.filter(groupe => {
        const demandes = codePostalRecherche.split(',').map(demande => demande.trim());

        const codesPostaux = demandes.filter(demande => /^\d{5}$/.test(demande)); // Tous les codes postaux
        const prefixesTelephones = demandes.filter(demande => /^0[67]$/.test(demande)); // Préfixes 06 ou 07

        if (codesPostaux.length > 0 && prefixesTelephones.length > 0) {
            // Filtrer par code postal et par préfixe de téléphone
            return codesPostaux.includes(groupe.codePostal) && 
                   prefixesTelephones.some(prefixe => groupe.numeroTelephone.startsWith(prefixe));
        } else if (codesPostaux.length > 0) {
            // Filtrer uniquement par code postal
            return codesPostaux.includes(groupe.codePostal);
        } else if (prefixesTelephones.length > 0) {
            // Filtrer uniquement par préfixe de téléphone
            return prefixesTelephones.some(prefixe => groupe.numeroTelephone.startsWith(prefixe));
        }
        return false; // Si rien ne correspond, ne pas inclure
    });

    // Fonction pour générer le CSV
    const downloadCSV = () => {
        const csvRows = [];
        const isWindows = navigator.platform.indexOf('Win') > -1; // Détecter si l'OS est Windows
        const separator = isWindows ? ',' : ';'; // Déterminer le séparateur

        // Ajouter les en-têtes
        csvRows.push(['Nom', 'Prénom', 'Adresse', 'Code Postal', 'Numéro de Téléphone', 'Email'].join(separator) + ';');

        // Ajouter les données des groupes filtrés
        groupesFiltres.forEach(groupe => {
            const row = [
                groupe.nom,
                groupe.prenom,
                groupe.adresse,
                groupe.codePostal,
                groupe.numeroTelephone,
                groupe.email
            ].join(separator) + ';'; // Ajouter ';' à la fin
            csvRows.push(row);
        });

        // Créer un blob et un lien pour le téléchargement
        const csvString = csvRows.join('\n');
        const blob = new Blob([csvString], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        
        // Créer un lien et déclencher le téléchargement
        const a = document.createElement('a');
        a.setAttribute('href', url);
        a.setAttribute('download', 'groupes.csv');
        a.click();
        URL.revokeObjectURL(url); // Libérer l'URL
    };

    // Fonction pour gérer l'importation du fichier CSV
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            Papa.parse(file, {
                header: true,
                skipEmptyLines: true,
                complete: (results) => {
                    const data = results.data;
                    console.log('Données importées:', data); // Log des données importées

                    if (Array.isArray(data) && data.length > 0) {
                        const validData = data.map(groupe => ({
                            nom: groupe.Nom,
                            prenom: groupe.Prénom,
                            adresse: groupe.Adresse,
                            codePostal: groupe['Code Postal'],
                            numeroTelephone: groupe['Numéro de Téléphone'],
                            email: groupe.Email
                        }));

                        console.log('Données valides à envoyer:', validData); // Log des données valides

                        // Mettre à jour l'état avec les données valides sans les sauvegarder
                        setGroupes(prevGroupes => [...prevGroupes, ...validData]);
                    } else {
                        console.error('Le fichier CSV ne contient pas de données valides.');
                    }
                },
                error: (error) => {
                    console.error('Error parsing CSV:', error);
                }
            });
        }
    };

    return (
        <div className='dashboard-container'>
            <NavbarTop />
            <Navbar />

            <div className='app'>
            <h1>Liste des Contacts</h1>
            
                <div className="flex">
                <input 
                    type="text" 
                    value={codePostalRecherche} 
                    onChange={handleCodePostalChange} 
                    placeholder="Rechercher un code postal ou un numéro de téléphone"
                    className="input"
                />
                <button 
                    onClick={downloadCSV} 
                    className="button"
                    disabled={groupesFiltres.length === 0} // Désactiver si aucun groupe n'est filtré
                >
                    Télécharger CSV
                </button>
                </div>

                <table className="table">
                    <thead>
                        <tr>
                            <th>Nom</th>
                            <th>Prénom</th>
                            <th>Adresse</th>
                            <th>Code Postal</th>
                            <th>Numéro de Téléphone</th>
                            <th>Email</th>
                        </tr>
                    </thead>
                    <tbody className="text-gray">
                        {Array.isArray(groupesFiltres) && groupesFiltres.length > 0 ? (
                            groupesFiltres.map((groupe, index) => (
                                <tr key={index}>
                                    <td>{groupe.nom}</td>
                                    <td>{groupe.prenom}</td>
                                    <td>{groupe.adresse}</td>
                                    <td>{groupe.codePostal}</td>
                                    <td>{groupe.numeroTelephone}</td>
                                    <td>{groupe.email}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="6" className="text-center">Aucun groupe trouvé</td> {/* Message si aucun groupe n'est trouvé */}
                            </tr>
                        )}
                    </tbody>
                </table>

                                {/* Bouton pour importer le fichier JSON */}
                                <input 
                    type="file" 
                    accept=".csv" 
                    onChange={handleFileChange} 
                    className="input"
                />
            </div>
        </div>
    );
}

export default Carte;