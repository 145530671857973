import React from "react";
import { Link } from 'react-router-dom';

function Error404() {
    return (
        <div className="error404-page">
            <img src="error404.svg" alt="Error 404" />
            <div>
                <h1>Error 404</h1>
                <p>Uh Oh, something happened.</p>

                <Link to="/" className="btn-home">Go back to the home page</Link>
            </div>
        </div>
    );
}

export default Error404;